import React, {useState} from "react";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';

export function DatePickerWithValidation({label, defaultValue, onValueChange}) {
    const [errMsgDatePickerValidation, setDatePickerValidationErrMsg] = useState("");

    function IsValidDateValue(value) {
        return dayjs(value).isValid();
    }

    return(
        <DatePicker label={label} sx={{m:2}} defaultValue={IsValidDateValue(defaultValue)? defaultValue=dayjs(defaultValue) : ""}
            onError={(errMsg) => {setDatePickerValidationErrMsg(errMsg)}} 
            slotProps={{textField: {helperText: errMsgDatePickerValidation}}} 
            onChange={(value) => {onValueChange && onValueChange(value, IsValidDateValue(value));}}>
        </DatePicker>
    );
}

export function TextFieldForValidNumber({label, defaultValue, onValueChange}) {
    const [value, setValue] = useState(defaultValue);
    const isValidNumber = IsValidNumberString(value);

    function IsValidNumberString(string) {
        let regexp = /^[1-9][0-9,]*$/;

        return(regexp.test(string));
    }

    return(
        <TextField label={label} sx={{m:2}} error={!isValidNumber} defaultValue={defaultValue} autoComplete="off"
            onChange={(event) => {setValue(event.target.value); onValueChange && onValueChange(event.target.value, IsValidNumberString(event.target.value));}}>
        </TextField>
    );
}

