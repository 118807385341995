import {useRef} from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

function ExportToFile(sortedPayments) {
    const data = {scheduledPayments : [...sortedPayments]};
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], {type: "application/json"});
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.href = url;
    a.download = "payments-schedule.json";
    a.click();
    URL.revokeObjectURL(url);
}


export function ImportExportButtons({sortedPayments, setSortedPayments}) {
    const inputFile = useRef(null);

    function onChangeFile(ev) {
        ev.stopPropagation();
        ev.preventDefault();

        const file = ev.target.files[0];
    
        const reader = new FileReader();
        reader.onload = () => {
            const jsonString = reader.result;
            const data = JSON.parse(jsonString);

            setSortedPayments(data.scheduledPayments);
        }

        reader.readAsText(file);
    }

    return(
        <>
            <Stack spacing={0.5} direction="row" sx={{justifyContent: "start", p: 2}}>
                <Button variant="outlined" size="small" onClick={() => {ExportToFile(sortedPayments)}} >入出金予定のエクスポート</Button>
                <Button variant="outlined" size="small" onClick={() => {inputFile.current.click()}} >入出金予定のインポート</Button>
            </Stack>
            <input type='file' accept='.json' id='file' ref={inputFile} onChange={onChangeFile} style={{display: 'none'}} />
        </>
    )
}