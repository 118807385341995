
const isLoggingEnabled = false;

export function LoadDataFromIndexedDB(setSortedPayments) {
    const request = indexedDB.open("PhogePayManDB");
    
    request.onerror = (ev) => {
        // TODO: error handling. e.g. user didn't grant permission for the app to create DB.
    }

    request.onupgradeneeded = (ev) => {
        if(isLoggingEnabled) console.log("Entering: onupgradeneeded");

        const dbNew = ev.target.result;
        const objectStore = dbNew.createObjectStore("data");

        if(isLoggingEnabled) console.log(objectStore);
    }

    request.onsuccess = (ev) => {
        if(isLoggingEnabled) console.log("LoadDataFromIndexedDB: indexedDB.open.onsuccess");

        const db = ev.target.result;      // db is the database.

        const transaction = db.transaction(["data"]);   // transaction is the readonly transaction for getting data from "data" object store.

        transaction.oncomplete = (ev) => {
            if(isLoggingEnabled) console.log("transaction.oncomplete");
            if(isLoggingEnabled) console.log(ev);
        }

        transaction.onerror = (ev) => {
            if(isLoggingEnabled) console.log("transaction.onerror");
            if(isLoggingEnabled) console.log(ev);
        }

        const objectStore = transaction.objectStore("data");
        const request = objectStore.get("payments record");

        request.onerror = (ev) => {
            // TODO: error handling.
            if(isLoggingEnabled) console.log("get.onerror");
            if(isLoggingEnabled) console.log(ev);
        }

        request.onsuccess = (ev) => {
            if(isLoggingEnabled) console.log("get.onsuccess");

            if(ev.target.result === undefined) {
                if(isLoggingEnabled) console.log("get succeeeds but result is undefined. Setting empty array to Payments state.");
                setSortedPayments([]);
            } else {
                // TODO: Call NormalizeInput.
                setSortedPayments(ev.target.result);
            }
            
            if(isLoggingEnabled) console.log(ev);
            if(isLoggingEnabled) console.log(request);
        }
    }
}

export function SaveDataToIndexedDB(sortedPayments) {

    if(isLoggingEnabled) console.log("Entering: SaveDataToIndexedDB")

    const request = indexedDB.open("PhogePayManDB");
    
    request.onerror = (ev) => {
        // TODO: error handling. e.g. user didn't grant permission for the app to create DB.
    }

    request.onsuccess = (ev) => {
        if(isLoggingEnabled) console.log("SaveDataToIndexedDB: indexedDB.open.onsuccess");

        const db = ev.target.result;      // db is the database.

        const transaction = db.transaction(["data"], "readwrite");   // transaction is the readwrite transaction for saving data to "data" object store.

        transaction.oncomplete = (ev) => {
            if(isLoggingEnabled) console.log("SaveDataToIndexedDB: transaction.oncomplete");
            if(isLoggingEnabled) console.log(ev);
        }

        transaction.onerror = (ev) => {
            if(isLoggingEnabled) console.log("SaveDataToIndexedDB: transaction.onerror");
            if(isLoggingEnabled) console.log(ev);
        }

        const objectStore = transaction.objectStore("data");
        const request = objectStore.put(sortedPayments, "payments record");

        request.onerror = (ev) => {
            // TODO: error handling.
            if(isLoggingEnabled) console.log("get.onerror");
            if(isLoggingEnabled) console.log(ev);
        }

        request.onsuccess = (ev) => {
            if(isLoggingEnabled) console.log("get.onsuccess");
            
            if(isLoggingEnabled) console.log(ev);
            if(isLoggingEnabled) console.log(request);
        }
    }
}

// TODO: remove console.log.