import dayjs from 'dayjs';

export function SortPayments(unsortedPayments) {
    let payments = unsortedPayments;
  
    payments.sort((first, second) => {
        if(dayjs(first.nextPayDay) < dayjs(second.nextPayDay)) {
            return -1;
        } else if(dayjs(first.nextPayDay) === dayjs(second.nextPayDay)) {
            return 0;
        }
        return 1;
    });
  
    return payments;
  }
  