import {useState} from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import RecordPaymentDialog from './RecordPaymentDialog';
import AddEditScheduleDialog from './AddEditScheduleDialog';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { EnsureStringFormatForAmount, EnsureStringFormatForDate } from './CommonFormatNormalizer';
import { SortPayments } from './CommonFunctions';

dayjs.extend(duration);

function PaymentsTable({sortedPayments, setSortedPayments}) {

    function getDisplayStringForRepetition(repetition) {
        const mappingTable = {
            "none": "なし",
            "monthly": "毎月",
            "weekly": "毎週",
            "bi-monthly": "隔月",
            "twice-a-year": "年 2 回",
            "every-three-months": "3 か月に 1 回",
            "every-four-months": "4 か月に 1 回",
            "every-year": "毎年",
            "bi-yearly": "隔年",
        };

        return(mappingTable[repetition]);
    }

    function DeletePayment(index) {
        const newSortedPayments = sortedPayments.toSpliced(index, 1);
        
        setSortedPayments(newSortedPayments);
    }

    const [isRecordPaymentDialogOpened, setRecordPaymentDialogOpened] = useState(false);
    const [paymentToRecord, setPaymentToRecord] = useState({});

    function GetIncrementDuration(repetition) {
        const mappingTable = {
            "monthly": dayjs.duration(1, "month"),
            "weekly": dayjs.duration(1, "week"),
            "bi-monthly": dayjs.duration(2, "month"),
            "twice-a-year": dayjs.duration(6, "month"),
            "every-three-months": dayjs.duration(3, "month"),
            "every-four-months": dayjs.duration(4, "month"),
            "every-year": dayjs.duration(1, "year"),
            "bi-yearly": dayjs.duration(2, "year"),
        };

        return(mappingTable[repetition]);
    }

    function AdvanceNextPayDay(index) {
        if(sortedPayments[index].repetition === "none") {
            // If the payment is one-off, delete the item after the payment.
            DeletePayment(index);
            return;
        }

        // Otherwise advance next pay date by the amount designated by repetition.
        const newUnsortedPayments = [...sortedPayments];
        const currentDate = dayjs(newUnsortedPayments[index].nextPayDay);
        let newDate = currentDate.add(GetIncrementDuration(newUnsortedPayments[index].repetition));

        newUnsortedPayments[index].nextPayDay = EnsureStringFormatForDate(newDate);

        setSortedPayments(SortPayments(newUnsortedPayments));
    }

    const [isAddEditScheduleDialogOpened, setAddEditScheduleDialogOpened] = useState(false);
    const [schedule, setSchedule] = useState({});
    const [isIncomeMode, setIsIncomeMode] = useState(false);

    function AddEditSchedule(newRecord) {
        let newUnsortedPayments;

        if(newRecord.index === -1) {
            // Add
            newUnsortedPayments = [...sortedPayments, newRecord];
        } else {
            // Edit
            newUnsortedPayments = [...sortedPayments];
            newUnsortedPayments[newRecord.index] = {name: newRecord.name, repetition: newRecord.repetition, nextPayDay: newRecord.nextPayDay, amount: newRecord.amount};
        }

        setSortedPayments(SortPayments(newUnsortedPayments));
    }

    function DecoratedNextPayDay({isOverDue, date}) {
        if(isOverDue) {
            return(
                <Tooltip title="期限超過">
                    <>
                    {"❗" + date}
                    </>
                </Tooltip>
            );
        } else {
            return(
                <>
                    {date}
                </>
            );
        }
    }

    function ShowSelectionRectangle(e, accum) {
        let firstItem = document.getElementById("firstItem");
        let style = document.getElementById("selectedRegion").style;

        let rectTop = firstItem.offsetParent.offsetTop + firstItem.offsetTop;
        let rectLeft = firstItem.offsetParent.offsetLeft + firstItem.offsetLeft;
        let rectWidth = firstItem.offsetWidth;
        let rectHeight = e.target.offsetTop - firstItem.offsetTop + e.target.offsetHeight - 8;

        style.display = "block";
        style.top = rectTop + "px";
        style.left = rectLeft + "px";
        style.width = rectWidth + "px";
        style.height = rectHeight + "px";

        let balloonStyle = document.getElementById("balloon").style;
        balloonStyle.display = "block";
        balloonStyle.top = rectTop + rectHeight - 36 + "px";
        balloonStyle.left = rectLeft + rectWidth + 22 + "px";

        let balloonText = document.getElementById("balloon");
        balloonText.textContent = "累計金額：\n" + accum + " 円";

    }

    function HideSelectionRectangle() {
        document.getElementById("selectedRegion").style.display = "none";
        document.getElementById("balloon").style.display = "none";
    }

    function CalculateRepeatedPayments(payment, dateUpUntil) {
        // payment could happen multiple times from payment.nextPayDay through dateUpUntil.

        if(payment.repetition === "none") {
            return parseInt(payment.amount.replace(/,/g, ''));
        } else {
            let accum = 0;
            const amount = parseInt(payment.amount.replace(/,/g, ''));

            for(let date = dayjs(payment.nextPayDay); date <= dayjs(dateUpUntil); date = dayjs(date).add(GetIncrementDuration(payment.repetition))) {
                accum += amount;
            }
            
            return accum;
        }
    }

    return (
        <section>
        <TableContainer component={Paper} sx={{width: "max-content", padding: 2}}>
          <h2>入出金予定</h2>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>支払い先</TableCell>
                <TableCell align="right">次の支払い日</TableCell>
                <TableCell align="right">繰り返し</TableCell>
                <TableCell align="right">金額</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
                {sortedPayments.map((payment, index) => {
                    const isIncomeMode = parseInt(payment.amount.replace(/,/g, '')) > 0;
                    const isOverDue = dayjs(payment.nextPayDay).isBefore(dayjs()/*today*/, "day");

                    // Accumlate all payments of items in range [0 - index). Note that each item could happen multiple times by the date of current item.
                    let accumOfPrev = 0;
                    for(let i = 0; i < index; i++){
                        accumOfPrev += CalculateRepeatedPayments(sortedPayments[i], payment.nextPayDay);
                    }

                    const accum = EnsureStringFormatForAmount(accumOfPrev + parseInt(payment.amount.replace(/,/g, '')));

                    return(
                        <TableRow
                        key={index}
                        className={isOverDue? "OverDue" : ""}
                        >
                        <TableCell sx={{padding: "1px 16px"}}>{payment.name}</TableCell>
                        <TableCell sx={{padding: "1px 16px"}} align="right"><DecoratedNextPayDay isOverDue={isOverDue} date={payment.nextPayDay} /></TableCell>
                        <TableCell sx={{padding: "1px 16px"}} align="right">{getDisplayStringForRepetition(payment.repetition)}</TableCell>
                        <TableCell sx={{padding: "1px 16px"}} align="right"
                            className={isIncomeMode? "" : "NegativeAmount"}
                            id={(index===0)? "firstItem" : ""}
                            onMouseEnter={(index!==0)? (e) => {ShowSelectionRectangle(e, accum);} : (e) => {}}
                            onMouseLeave={(index!==0)? (e) => {HideSelectionRectangle();} : (e) => {}}>
                                {payment.amount} 円
                        </TableCell>
                        <TableCell sx={{padding: "1px 16px"}} align="center">
                            <Stack spacing={0.5} sx={{padding: "1px 6px"}} direction="row">
                                <Button sx={{padding: "0px 10px"}} variant="contained" size="small" onClick={() => {setPaymentToRecord({...payment, index: index});setIsIncomeMode(isIncomeMode);setRecordPaymentDialogOpened(true)}}>{isIncomeMode? "入金を記録" : "出金を記録"}</Button>
                                <Button sx={{padding: "0px 10px"}}variant="outlined" size="small" onClick={() => {setSchedule({...payment, index: index});setIsIncomeMode(isIncomeMode);setAddEditScheduleDialogOpened(true);}} >予定の編集</Button>
                            </Stack>
                        </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
          </Table>
          <Stack spacing={0.5} direction="row" sx={{justifyContent: "end", p: 2}}>
                    <Button variant="outlined" size="small" onClick={() => {setSchedule(null);setIsIncomeMode(true);setAddEditScheduleDialogOpened(true);}}>新規入金予定の追加</Button>
                    <Button variant="outlined" size="small" onClick={() => {setSchedule(null);setIsIncomeMode(false);setAddEditScheduleDialogOpened(true);}}>新規出金予定の追加</Button>
          </Stack>
          <div id="selectedRegion"></div>
          <div id="balloon">ふきだし</div>
        </TableContainer>

        <RecordPaymentDialog isOpened={isRecordPaymentDialogOpened} setOpenClose={setRecordPaymentDialogOpened} record={paymentToRecord} isIncomeMode={isIncomeMode} onSubmit={AdvanceNextPayDay}/>

        <AddEditScheduleDialog isOpened={isAddEditScheduleDialogOpened} setOpenClose={setAddEditScheduleDialogOpened} record={schedule} isIncomeMode={isIncomeMode} onSubmit={AddEditSchedule} onDelete={DeletePayment} />
    
        </section>
      );

}

export default PaymentsTable;
