import dayjs from 'dayjs';

export function EnsureStringFormatForDate(date) {
    return dayjs(date).format("YYYY/M/D");
}

export function EnsureStringFormatForAmount(value) {
    if(typeof(value) === "number") {
        return value.toLocaleString();
    } else {
        return parseInt(value.replace(/,/g, "")).toLocaleString();
    }
}

export function EnsureAbsoluteNumberString(value) {
    let result = Math.abs(parseInt(value.replace(/,/g, ""))).toLocaleString();

    return result;
}

export function EnsureNegativeNumberString(value) {
    let result = (-(Math.abs(parseInt(value.replace(/,/g, ""))))).toLocaleString();

    return result;
}