import React, {useState, useEffect} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ja';
import { FormControl } from "@mui/material";
import { EnsureStringFormatForAmount, EnsureStringFormatForDate, EnsureAbsoluteNumberString, EnsureNegativeNumberString } from "./CommonFormatNormalizer";
import { DatePickerWithValidation, TextFieldForValidNumber } from "./CommonControls";
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';


function TextFieldForNonEmptyString({label, defaultValue, onValueChange}) {
    const [value, setValue] = useState(defaultValue);
    const isValidName = IsValidValue(value);

    function IsValidValue(value) {
        return (value !== "");
    }

    return(
        <TextField label={label} sx={{m:2}} defaultValue={defaultValue} error={!isValidName} autoComplete="off"
            onChange={(event) => {setValue(event.target.value);onValueChange && onValueChange(event.target.value, IsValidValue(event.target.value))}}>
        </TextField>
    );
}

function RepetitionSelector({label, defaultValue, onValueChange}) {
    return(
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select sx={{m:2, minWidth:150}} label={label} defaultValue={defaultValue} onChange={(event) => {onValueChange && onValueChange(event.target.value);}}>
                <MenuItem value={"none"}>なし</MenuItem>
                <MenuItem value={"monthly"}>毎月</MenuItem>
                <MenuItem value={"weekly"}>毎週</MenuItem>
                <MenuItem value={"bi-monthly"}>隔月</MenuItem>
                <MenuItem value={"twice-a-year"}>年 2 回</MenuItem>
                <MenuItem value={"every-three-months"}>3 か月に 1 回</MenuItem>
                <MenuItem value={"every-four-months"}>4 か月に 1 回</MenuItem>
                <MenuItem value={"every-year"}>毎年</MenuItem>
                <MenuItem value={"bi-yearly"}>隔年</MenuItem>
            </Select>
        </FormControl>
    );
}

function AddEditScheduleDialog({isOpened, setOpenClose, record, isIncomeMode, onSubmit, onDelete}) {
    const [name, setName] = useState("");
    const [isNameValid, setIsNameValid] = useState(false);
    const [nextPayDay, setNextPayDay] = useState(null);
    const [isNextPayDayValid, setIsNextPayDayValid] = useState(false);
    const [repetition, setRepetition] = useState("none");
    const [amount, setAmount] = useState(0);
    const [isAmountValid, setIsAmountValid] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const areAllDatavalid = isNameValid && isNextPayDayValid && isAmountValid;

    function ResetState(isValid) {
        setIsNameValid(isValid);
        setIsNextPayDayValid(isValid);
        setIsAmountValid(isValid);
    }

    const isEditMode = (record !== null);
    const absAmountString = (record && record.amount !== undefined)? EnsureAbsoluteNumberString(record.amount) : 0;

    useEffect(() => {
        if(isEditMode) {
            ResetState(true);
        } else {
            ResetState(false);
        }
    }, [isEditMode]);

    useEffect(() => {
        if(isOpened) {
            // Reset field values when the dialog is opened (when isOpened changes from false to true). [AB#67]
            setName("");
            setNextPayDay(null);
            setRepetition("none");
            setAmount(0);
        }
    }, [isOpened]);
    
   useEffect(() => {
        if(record !== null) {
            if(record.name !== undefined) setName(record.name);
            if(record.nextPayDay !== undefined) setNextPayDay(record.nextPayDay);
            if(record.repetition !== undefined) setRepetition(record.repetition);
            if(record.amount !== undefined) setAmount(EnsureAbsoluteNumberString(record.amount));
            setIsDirty(false);
        }
    }, [record])


    let dialogTitle, nextPayDayName, subjectName;
    const submitButtonName = isEditMode? "変更の保存" : "予定の追加";
    
    if(isIncomeMode) {
        dialogTitle = isEditMode? "入金予定の編集" : "新規入金予定の追加";
        nextPayDayName = isEditMode? "次の入金日" : "最初の入金日";
        subjectName = "支払い元";
    } else {
        dialogTitle = isEditMode? "出金予定の編集" : "新規出金予定の追加";
        nextPayDayName = isEditMode? "次の支払日" : "最初の支払日";
        subjectName = "支払い先";
    }
    
    const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] = useState(false);
    
    function OnConfirmationDialogClose(value) {

        if(value) {
            // Yes - go ahead and delete the item. Invoke callback in parent to handle delete action and close the confirmation dialog as well as AddEditScheduleDialog.
            setIsConfirmationDialogOpened(false);
            setOpenClose(false);
            onDelete(record.index);
        } else {
            // No - do not delete. Just close the confirmation dialog with NOP.
            setIsConfirmationDialogOpened(false);
        }

    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
            <Dialog open={isOpened} >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        以下の情報を入力後、「{submitButtonName}」ボタンを押してください。
                    </DialogContentText>
                    <Box sx={{mt:2, p:2, border:1, borderRadius:1, borderColor: "primary.light"}}>
                        <TextFieldForNonEmptyString label={subjectName} defaultValue={record? record.name : ""} onValueChange={(value, isValid) => {setIsDirty(true);setName(value);setIsNameValid(isValid);}}/>
                        <DatePickerWithValidation label={nextPayDayName} defaultValue={record? record.nextPayDay : ""} onValueChange={(value, isValid) => {setIsDirty(true);setNextPayDay(value);setIsNextPayDayValid(isValid);}}/>
                        <RepetitionSelector label="繰り返し" defaultValue={record? record.repetition : "none"} onValueChange={(value) => {setIsDirty(true);setRepetition(value);}}/>
                        <TextFieldForValidNumber label="金額" defaultValue={absAmountString} onValueChange={(value, isValid) => {setIsDirty(true);setAmount(value); setIsAmountValid(isValid);}} />
                    </Box>
                </DialogContent>
                <Stack direction="row">
                    <Container sx={{width: "50%"}}>
                        <DialogActions sx={{justifyContent: "start"}}>
                            {isEditMode?
                                <Button variant="outlined" color="warning" sx={{align: "left"}} disabled={isDirty} onClick={() => {setIsConfirmationDialogOpened(true);}}>予定の削除</Button> : <></>
                            }
                        </DialogActions>
                    </Container>
                    <Container sx={{width: "50%"}}>
                        <DialogActions>
                            <Button variant="contained" disabled={!areAllDatavalid} onClick={() => {setOpenClose(false); onSubmit({name: name, repetition: repetition, nextPayDay: EnsureStringFormatForDate(nextPayDay), amount: isIncomeMode? EnsureStringFormatForAmount(amount) : EnsureNegativeNumberString(amount), index: isEditMode? record.index : -1});}}>{submitButtonName}</Button>
                            <Button variant="outlined" onClick={() => {setOpenClose(false);}}>キャンセル</Button>
                        </DialogActions>
                    </Container>
                </Stack>

                <ConfirmationDialog isOpened={isConfirmationDialogOpened} onClose={OnConfirmationDialogClose} />

            </Dialog>
        </LocalizationProvider>
    );
}

function ConfirmationDialog({isOpened, onClose}) {
    return (
        <Dialog open={isOpened}>
            <DialogTitle>
                予定の削除の確認
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    予定を削除していいですか？
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {onClose(true);}}>はい</Button>
                <Button onClick={() => {onClose(false);}} autoFocus>いいえ</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddEditScheduleDialog;