import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {useState, useEffect} from 'react';

import {initialPaymentsDummyData} from "./DummyData";
import { EnsureStringFormatForAmount, EnsureStringFormatForDate } from './CommonFormatNormalizer';
import { SortPayments } from './CommonFunctions';

import './App.css';
import PaymentsTable from './PaymentsTable';
import {LoadDataFromIndexedDB, SaveDataToIndexedDB} from './IndexedDBAdaptor';
import {ImportExportButtons} from './FileIO';


function NormalizeInput(payments) {
  return payments.map((payment, index) => {
      let normalizedPayment = payment;

      normalizedPayment.amount = EnsureStringFormatForAmount(normalizedPayment.amount);
      normalizedPayment.nextPayDay = EnsureStringFormatForDate(normalizedPayment.nextPayDay);

      return normalizedPayment;
  });
}



function App() {
  const [sortedPayments, setSortedPayments] = useState([]);
  const useDummyDataForDebug = false;
  let setSortedPaymentsWrapper = useDummyDataForDebug? setSortedPayments : (payments) => {  // This wrapper makes sure that whenever payments state is updated by the inner compoments the change is also committed to IndexedDB.
    setSortedPayments(payments);
    SaveDataToIndexedDB(payments);
  };

  useEffect(() => {
    if(useDummyDataForDebug) {
      // Hard coded, dummy data for debugging.
      setSortedPayments(SortPayments(NormalizeInput(initialPaymentsDummyData)));
    } else {
      // Load data from IndexedDB. This also initializes the db for the first time use.
      LoadDataFromIndexedDB(setSortedPayments);
    }
  }, [useDummyDataForDebug]);

  return (
    <div className="App">
      <PaymentsTable sortedPayments={sortedPayments} setSortedPayments={setSortedPaymentsWrapper}/>
      <ImportExportButtons sortedPayments={sortedPayments} setSortedPayments={setSortedPaymentsWrapper} />
    </div>
  );
}

export default App;
