export const initialPaymentsDummyData = [
    {
      name: "One-off pay for X",
      repetition: "none",
      nextPayDay: "2023/10/10",
      amount: "-5000",
    },
    {
      name: "Monthly pay for Y",
      repetition: "monthly",
      nextPayDay: "2023/10/15",
      amount: "-8000",
    },
    {
      name: "Weekly pay for Z",
      repetition: "weekly",
      nextPayDay: "2023/10/9",
      amount: "-3000",
    },
    {
      name: "Bi-monthly pay",
      repetition: "bi-monthly",
      nextPayDay: "2023/10/07",
      amount: "-100",
    },
    {
      name: "Twice a year pay",
      repetition: "twice-a-year",
      nextPayDay: "2023/12/31",
      amount: "-10000",
    },
    {
      name: "Every four months pay",
      repetition: "every-four-months",
      nextPayDay: "2023/11/2",
      amount: "-30000",
    },
    {
      name: "Every three months pay",
      repetition: "every-three-months",
      nextPayDay: "2023/12/15",
      amount: "-300",
    },
    {
      name: "Every year pay",
      repetition: "every-year",
      nextPayDay: "2023/11/23",
      amount: "-7000",
    },
    {
      name: "Bi-yearly pay",
      repetition: "bi-yearly",
      nextPayDay: "2024/1/1",
      amount: "-100000",
    },
    {
      name: "Monthly income",
      repetition: "monthly",
      nextPayDay: "2023/11/25",
      amount: "12345",
    },
  ];
  