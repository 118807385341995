import React, {useState} from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { EnsureStringFormatForAmount, EnsureStringFormatForDate, EnsureAbsoluteNumberString } from "./CommonFormatNormalizer";
import { DatePickerWithValidation, TextFieldForValidNumber } from "./CommonControls";


function RecordPaymentDialog({isOpened, setOpenClose, record, isIncomeMode, onSubmit}) {
    const [isStringValidPayAmount, setValidPayAmountFlag] = useState(true);
    const [isValidPayDate, setValidPayDateFlag] = useState(true);
    const areAllDatavalid = isStringValidPayAmount && isValidPayDate;

    let payDate = dayjs(record.nextPayDay);

    let payAmount = (record && record.amount !== undefined)? EnsureAbsoluteNumberString(record.amount) : 0;

    const subjectName = isIncomeMode? "支払い元" : "支払い先";

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
            <Dialog open={isOpened} >
                <DialogTitle>{isIncomeMode? "入金を記録" : "出金を記録"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        支払いの日付と金額を確認後、「記録」ボタンを押してください。
                    </DialogContentText>
                    
                    <Box sx={{mt:2, p:2, border:1, borderRadius:1, borderColor: "primary.light"}}>
                    <DialogContentText >
                        {subjectName}：{record.name}
                    </DialogContentText>

                    <DatePickerWithValidation label="支払い日" defaultValue={payDate} onValueChange={(value, isValid) => {setValidPayDateFlag(isValid);payDate = value;}}/>
                    <TextFieldForValidNumber label="金額" defaultValue={payAmount} onValueChange={(value, isValid) => {setValidPayAmountFlag(isValid);payAmount = value;}} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disabled={!areAllDatavalid} onClick={() => {console.log("Date = " + EnsureStringFormatForDate(payDate) + ", Amount = " + EnsureStringFormatForAmount(payAmount)); setOpenClose(false); onSubmit(record.index);}}>記録</Button>
                    <Button variant="outlined" onClick={() => setOpenClose(false)}>キャンセル</Button>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
};

export default RecordPaymentDialog;